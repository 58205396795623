import forList from "@/mixin/forList";
import forEdit from "@/mixin/forEdit";

// 函数创建一个空的菜单数据
const initialPB = () => ({
	name: "",
	rolesId: "",
    oder: 99,
	remark: ""
});

export default {
	name: "BasicPost",
	mixins: [forList, forEdit],

	data() {
		return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
			SP_: {
				postName: "",      // 功能描述
			},

			PB: null, // POST BODY: 当前对话框编辑的数据

            // 验证规则
            rules: {
                name: {
                    required: true,
                    message: "请输入岗位名称"
                },
                rolesId: {
                    required: true,
                    message: "请选择角色"
                },
                oder: {
                    required: true,
                    message: "请输入排序号"
                },
            },

            // 所有岗位
            roleList: []

		};
	},

	computed: {

		// 对话框状态由 PB 是否有值产生
		showAddPupop () {
			return !!this.PB
		},
	},

	methods: {

		fetchData() {
			if (this.loading) return;
			this.loading = true;

			const data = {...this.extractParams()};
			this.$oxios({
				url: "api/post/getList",
				method: "post",
				data
			})
			.then(res => {
				if (res.code === 2000 && res.data.postlist) {
	
					this.total = res.data.count;
					this.roleList = res.data.roleList;
					const list = res.data.postlist.map((item, index) => {
						const data = {
							...item,
							rolesId: item.roleid
						};
	
						return data;
					});
					this.tableData.splice(0, this.tableData.length, ...list)

				} else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
		},

		initialPB, // 创建一个新的空的菜单数据

		// 点击 "新增菜单"
		handleAddBtn() {
			this.PB = this.initialPB();
		},

		// 点击表格行操作按钮: 编辑 
		handleEdit(data) {
			this.PB = { ...data };
		},

		// 关闭对话框
		handleDialogClose() {
			this.PB = null
		},

		// 保存对话框
		handSubmitButton() {
			this.$refs.form.validate()

				.then(() => {
					this.posting = true;

                    const url = this.PB?.id ? "api/post/updatePost" : "api/post/addPost";
					const {id, name, rolesId, remark, oder} = this.PB;
					return this.$axios({
						url,
						method: "post",
						data: { id, name, rolesId, remark, oder }
					})
				})

				.then(res => {
					const fine = 2000 == res.code;
					this.$message({
						message: fine ? "保存成功" : res.msg || "保存失败",
						type: fine ? "success" : "warning"
					})

					if (fine) {
						this.PB = null;
						this.tableData.splice(0, this.tableData.length);
						this.fetchData();
					}

					return fine || Promise.reject(res.msg)
				})

				.catch(reason => {
					// this.$refs.form.validate 验证失败的拒绝结果是值 false
					// 对于验证失败，不用 message 提示，因为表单控件上已有提示
					reason && this.$message({
						message: reason || "请填写完整数据",
						type: "warning"
					});
					console.log(reason);
				})

				.then(() => {
					this.posting = false;
				})
		},

		// 点击表格行操作按钮: 删除
		async handleDelete(data) {
			this.$confirm("确定要删除吗")
			
			.then(() => {
				this.posting = true
				return this.$axios({
					url: "api/post/deletePost",
					method: "post",
					data: {
						postid: Number(data.id)
					}
				})
			})

			.then(res => {
				const success = 2000 == res.code;
				this.$message({
					message: success ? "删除成功" : res.msg || "删除失败",
					type: success ? "success" : "warning"
				})

				if (success) {
					this.tableData.splice(0, this.tableData.length);
					this.fetchData();
				}
				return success || Promise.reject(res)
			})
			
			.catch(reason => {
				console.warn(reason)
			})
			
			.then(() => {
				this.posting = false
			})
		},

	},

	created () {
		this.auth.save = this.$hasAuthFor("api/post/addPost");
		this.auth.edit = this.$hasAuthFor("api/post/updatePost");
		this.auth.delete = this.$hasAuthFor("api/post/deletePost");
	}
};