import forList from "@/mixin/forList";
import WxCode from "./WxCode.vue";

export default {
    name: "Voucher",
    components: {WxCode},
    mixins: [forList],

    data() {
        return {
            SP_: {
                searchCouName: "",   // 券名
                searchCouType: "",   // 使用方式
                searchGoodsNo: "",   // 商品编号
            },
            couponConfTypeEnum: [],  // 使用方式的选项
            offcialList: [],         // 公众号列表，包含各自的消息模板列表
            wxItem: {},              // 微信二维码的项
        };
    },

    methods: {

		// 获取列表数据
		fetchData() {
			if (this.loading) return;
            
			this.loading = true;
			this.$axios({
				url: "api/couponConf/queryCouponConfList",
				params: {...this.extractParams()}
			})
            .then(res => {
                if (res.code === 2000) {
                    this.total = res.data.count;
                    this.tableData.splice(0, this.tableData.length, ...res.data.couponConfList.map(it => {
                        if (it.type === 2 || it.type === 3) {        // 折扣券：显示折扣，不显示抵扣金额
                            delete it.amount;
                            it.discount += "%";
                        } else if (it.type === 1 || it.type === 4 || it.type === 5) { // 抵扣券：显示抵扣金额，不显示折扣
                            delete it.discount;
                            it.amount += "元"
                        }
                        if (it.time_type == 1) it.valid_time = "";
                        else if (it.valid_time === 0) it.valid_time = "永久有效";
                        if (it.show_amount === "0") it.show_amount = "免费";
                        return it;
                    }));
                    this.couponConfTypeEnum.splice(0, this.couponConfTypeEnum.length, ...res.data.couponConfTypeEnum)

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取券信息失败", reason);
                this.$message({
                    message: reason.msg || "获取券信息失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
		},


        // 点击 "新增菜单"
        handleAddBtn() {
            this.$router.push("/basic/voucher/add");
        },

        // 点击表格行操作按钮: 编辑
        handleEdit(index) {
            const data = this.tableData[index];
            this.$router.push("/basic/voucher/edit/" + (data.id || 5));
        },

        // 点击表格行操作按钮: 删除
        handleDelete(index) {
			const data = this.tableData[index];
			this.$confirm("要删除这个券吗")
            .then(() => {
                this.loading = true;

                return this.$axios({
                    url: "api/couponConf/deleteCouponConf",
                    method: "post",
                    data: { id : data.id }
                })
            })

            .then(res => {
                const success = 2000 == res.code;

                this.$message({
                    message: success ? "删除成功" : (res.msg || "删除失败"),
                    type: success ? "success" : "warning"
                })

                this.loading = false;
                if (success) this.fetchData();
            })

            .catch(reason => {
                console.warn(reason);
                this.loading = false;
            })
        },

        // 切换状态
        handleStateSwitch (value, index) {
            const item = this.tableData[index];
            const text = value === 1 ? "禁用" : "启用";
            
            // item.stateChanging = true;
            this.loading = true;
            this.$axios({
                url: "api/couponConf/updateCouponConfState",
                method: "post",
                data: {
                    id: item.id,
                    state: value
                }
            })

            .then(res => {
                if (res.code === 2000) {
                    item.disname = text;
                    this.$set(this.tableData, index, item);
                } else if (res.code !== 1003)
                    return Promise.reject(res)
            })

            .catch(reason => {
                console.log("切换券状态失败", reason);
                this.$message({
                    message: reason?.msg || text + "失败",
                    type: "warning"
                });
                item.is_disable = value === 1 ? 2 : 1;
            })

            .then(() => {
                // item.stateChanging = false;
                this.loading = false;
                this.$set(this.tableData, index, item)
            })
        },

        // 设置消息提醒
        handleSetMessage (index) {
            const data = this.tableData[index];
            this.$router.push(`/basic/voucher/message/${data.id}`)
        },

        // 扫码领劵二维码
        handlePresent (index) {
            const data = this.tableData[index];
            console.log("data",data)
            this.wxItem = data;
        },
    },

	created () {
		this.auth.save = this.$hasAuthFor("api/couponConf/saveCouponConf");
		this.auth.switch = this.$hasAuthFor("api/couponConf/updateCouponConfState");  // 启用禁用权限
		this.auth.delete = this.$hasAuthFor("api/couponConf/deleteCouponConf");
		this.auth.message = this.$hasAuthFor("api/couponConf/saveCouponConfMessage"); // 设置消息提醒
		this.auth.present = this.$hasAuthFor("api/couponConf/creatCustCouponWxCode"); // 二维码权限
	}
};