
import forEdit from "@/mixin/forEdit";
import editorOption from "@/utils/quillOpts";

export default {
    name: 'MemberLevelEdit',
    mixins: [forEdit],

    data() {
        return {
            // post body 请求体数据
            PB: {
                id: undefined, // 编辑才有id
                title: "",      
                integral: "",  
                level: "",      
                img: [],       
                discount: "",   
                remark: "",
            },
            rules: {
                title: {
                    required: true,
                    message: "请输入积分卡名称",
                    trigger: "blur"
                },
                integral: {
                    required: true,
                    message: "请输入积分标准",
                    trigger: "blur"
                },
                money: {
                    required: true,
                    message: "请输入办理业务费用",
                    trigger: "blur"
                },
                level: {
                    required: true,
                    message: "请输入积分等级",
                    trigger: "blur"
                },
                img: {
                    required: true,
                    message: "请上传图片",
                    trigger: "blur"
                },
                discount: {
                    required: true,
                    message: "请输入折扣率",
                    trigger: "blur"
                },
            },
            uploadHeaders: {         // 图片上传的请求头
                token: this.$store.state.token
            },
            pictures: [],         // 隐藏的 el-upload 的file
            posting: false,       // 状态: 上传图片
            editorOption,         // 富文本编辑器的配置

            auth: {
                save: false, // 修改并保存的权限
            }
        }
    },

    computed: {
        // 当前选中的系统的 区域配置
        areaList () {
            return this.systype.map(value => {
                return this.sysTypeEnum.find(
                    item => item.value === value);
            })
        }
    },

    methods: {

        // 获取页面数据，withoutProfile，只要配置信息
        fetchData(withoutProfile) {
			if (this.loading) return;
			this.loading = true;
			this.$axios({
				url: "api/level/info",
				method: "get",
				params: { id: this.PB.id }
			})
            .then(res => {
                if (res.code === 2000) {
                    this.PB = {...res.data.info}
                    if (!withoutProfile) {
                        const {PB} = this;
                        for (const key in PB) {
                            if (key == "img") {
                                PB.img = [{
                                    name: res.data.info.img.slice(res.data.info.img.lastIndexOf("/")+1),
                                    url: this.$baseURL + res.data.info.img,
                                    path: res.data.info.img
                                }]
                                
                            } else if (key == "remark") {
                                PB[key] = res.data.info[key].addHostToImg(this.$baseURL.slice(0, -1))
                                
                            } else {
                                PB[key] = res.data.info[key]
                            }
                        }
                    }

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        handleExceed() {
          // 上传超过一张图
          this.$message({
            message: "只能上传一张图片",
            type: "error",
          });
        },

        beforeFileUpload(file) {
            // 上传图片之前，判断图标格式
            const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
            const isLt2M = file.size / 1024 / 1024 < 5;
        
            if (!isImage) {
                this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!');
            }
            if (isImage && isLt2M) {
                // this.userInterface.uploadIconProcess = true;
                // file.progress = 0;
                this.PB.img.push(file)
                return true;
            }
            return false;
        },

        beforeFileUpload2(file) {
            // 上传图标之前，判断图标格式
            const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
            const isLt2M = file.size / 1024 / 1024 < 5;
      
            if (!isImage) {
              this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
            }
            if (!isLt2M) {
              this.$message.error('上传图片大小不能超过5MB!');
            }
            if (isImage && isLt2M) {
              return true;
            }
            return false;
          },
        
        handleProgress(e, file) {
            this.posting = true;
            const index = this.PB.img.indexOf(file);
            this.$set(this.PB.img, index, file)
        },

        // 上传文件成功
        handleUploadSuccess (res, file) {
            this.posting = false;
            const index = this.PB.img.indexOf(file);
            if (res.code === 2000) {

                const {oldName, filePath} = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                this.$set(this.PB.img, index, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
                this.$refs.form.clearValidate("img")
            } else {
                this.PB.img.splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },

        // 富文本上传文件成功
        handleUploadSuccess2 (res) {
            this.$refs.uploader.clearFiles()
            if (res.code === 2000) {
                const {oldName, filePath} = res.data.files;

                const quill = this.$refs.editor.quill
                let length = quill.getSelection().index;
                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(length, 'image', this.$baseURL + filePath)
                // 调整光标到最后
                quill.setSelection(length + 1)
            } else {
                this.$message({
                    message: "图片插入失败",
                    type: "error"
                })
            }
        },

        // 富文本中上传失败
        handleUploadFail () {
            this.$refs.uploader.clearFiles()
            this.$message({
                message: "图片插入失败",
                type: "error"
            })
        },

        // 删除文件
        handleIconDelete (index) {
            this.PB.img.splice(index, 1);
        },

        // 点击提交按钮
        handleSubmitButton () {
            this.$refs.form.validate()
            .then(() => {
                this.posting = true;
                return this.$axios({
                    url: "api/level/save",
                    method: "post",
                    // PB: {
                //     id: undefined, // 编辑才有id
                //     title: "",      
                //     integral: "",  
                //     level: "",      
                //     img: [],       
                //     discount: "",   
                //     remark: "",
                // },
                    data: {
                        id: this.PB.id || '', 
                        title: this.PB.title,      
                        integral: this.PB.integral,  
                        level: this.PB.level,      
                        discount: this.PB.discount,   
                        remark: this.PB.remark.removeImgHost(this.$baseURL),
                        img: this.PB.img.map(item => item.path).join(),
                        money: this.PB.money
                    }
                })
            })
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "保存成功" : res.msg || "保存失败",
                    type: success ? "success" : "warn"
                });
                if (success) {
                    this.$router.back()
                }
            })
            
            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: "保存失败",
                    type: "error"
                });
                console.warn(reason);
            })
            
            .then(() => {
                this.posting = false
            })
        },

        // 重置表单
        // resetPB () {
        //     this.PB.name = "";
        //     this.PB.img = [];
        //     this.PB.type = "";
        //     this.PB.goodsId = "";
        //     this.PB.href = "";
        //     this.PB.position = "";
        //     this.PB.content = "";
        //     this.PB.order = 99;
        // },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        }
    },

    watch: {},

    created () {
		this.auth.save = this.$hasAuthFor("api/level/save");
        // 有 id 的情况，在 @/mixin/forEdit 已经处理了，
        // 这个没有 id 也发送请求的目的是获取 岗位、系统 的配置信息
        // const id = Number(this.$route.params.id);
        // if (!id) {
        //     this.fetchData(true);
        // }
    },
}