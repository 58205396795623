
import forEdit from "@/mixin/forEdit";

export default {
    name: 'BannerEdit',
    mixins: [forEdit],

    data() {
        return {
            // post body 请求体数据
            PB: {
                id: undefined, // 编辑才有id
                name: "",      // banner名
                position: "",  // 位置
                type: "",      // 跳转方式
                img: [],       // 图片
                goodsId: "",   // 商品id
                href: "",      // 跳转链接
                order: 99,     // 排序
                content: "",   // 备注
            },
            rules: {
                name: {
                    required: true,
                    message: "请输入名称",
                    trigger: "blur"
                },
                position: {
                    required: true,
                    message: "请选择位置",
                    trigger: "blur"
                },
                type: {
                    required: true,
                    message: "请选择跳转方式",
                    trigger: "blur"
                },
                img: {
                    required: true,
                    message: "请上传图片",
                    trigger: "blur"
                },
                order: {
                    required: true,
                    message: "请输入排序号, 数字越大排序越后",
                    trigger: "blur"
                },
            },
            bannerPositionEnum: [],  // 位置集合
            bannerTypeEnum: [],      // 跳转方式集合
            goodsList: [],           // 可选商品列表
            uploadHeaders: {         // 图片上传的请求头
                token: this.$store.state.token
            },
            posting: false,          // 状态: 上传图片
            timer: null,             // 模糊搜索防抖的定时器
            goodsLoading: false,     // 状态：模糊查询商品名称中
        }
    },

    computed: {
        // 当前选中的系统的 区域配置
        areaList () {
            return this.systype.map(value => {
                return this.sysTypeEnum.find(
                    item => item.value === value);
            })
        },
    },

    methods: {

        // 获取页面数据，withoutProfile，只要配置信息
        fetchData(withoutProfile) {
			if (this.loading) return;
			this.loading = true;

			this.$axios({
				url: "api/config/getBannerInfo",
				method: "post",
				data: { id: this.PB.id }
			})
            .then(res => {
                if (res.code === 2000) {
                    this.bannerTypeEnum.splice(0, this.bannerTypeEnum.length, ...res.data.bannerTypeEnum);
                    this.bannerPositionEnum.splice(0, this.bannerPositionEnum.length, ...res.data.bannerPositionEnum);
    
                    if (!withoutProfile) {
                        const {PB} = this;
                        for (const key in PB) {
                            if (key == "img") {
                                PB.img = [{
                                    name: res.data.banner.img.slice(res.data.banner.img.lastIndexOf("/")+1),
                                    url: this.$baseURL + res.data.banner.img,
                                    path: res.data.banner.img
                                }]
    
                            } else {
                                PB[key] = res.data.banner[key]
                            }
                        }
    
                        if (res.data.banner.goodsId) {
                            this.goodsList.unshift({
                                id: res.data.banner.goodsId,
                                name: res.data.goodsName
                            })
                        }
                    }

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取banner失败", reason);
                this.$message({
                    message: reason.msg || "获取banner失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)

        },

        // 商品框输入名称模糊查询商品列表
        queryLikeGoods (str) {
            function doSearch () {
                this.goodsLoading = true;
                this.timer = null;
                this.$axios({
                    url: "api/goods/queryLikeGoods",
                    params: {goodsName: str}
                })
                .then(res => {
                    if (res.code === 2000) {
                        const {goodsList, PB: {goodsId}} = this;
                        goodsList.splice(0, goodsList.length, ...res.data.goodsList)

                    } else if (res.code !== 1003) return Promise.reject(res)
                })
                .catch(reason => {
                    this.$message({
                        message: reason.msg || "获取商品失败",
                        type: "warning"
                    })
                })
                .then(() => {
                    this.goodsLoading = false;
                })
            }

            if (this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(doSearch.bind(this), 500)
        },

        // 商品选中时，将可选列表的固定选项替换为选中的选项
        // handleGoodsChange (id) {
        //     const goods = this.goodsList.find(goods => goods.id === id);
        //     this.goodsList.splice(0, this.PB.goodsId?1:0, goods)
        // },

        handleExceed() {
          // 上传超过一张图
          this.$message({
            message: "只能上传一张图片",
            type: "error",
          });
        },

        beforeFileUpload(file) {
            // 上传图片之前，判断图标格式
            const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
            const isLt2M = file.size / 1024 / 1024 < 5;
        
            if (!isImage) {
                this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!');
            }
            if (isImage && isLt2M) {
                // this.userInterface.uploadIconProcess = true;
                // file.progress = 0;
                this.PB.img.push(file)
                return true;
            }
            return false;
        },
        
        handleProgress(e, file) {
            this.posting = true;
            const index = this.PB.img.indexOf(file);
            this.$set(this.PB.img, index, file)
        },

        // 上传文件成功
        handleUploadSuccess (res, file) {
            this.posting = false;
            const index = this.PB.img.indexOf(file);
            if (res.code === 2000) {

                const {oldName, filePath} = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                this.$set(this.PB.img, index, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
                this.$refs.form.clearValidate("img")
            } else {
                this.PB.img.splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },

        // 删除文件
        handleIconDelete (index) {
            this.PB.img.splice(index, 1);
        },

        // 点击提交按钮
        handleSubmitButton () {

            this.$refs.form.validate()
            .then(() => {
                const {type} = this.PB;
                if (type == 2) {
                    // 跳转商品
                    if (!this.PB.goodsId) {
                        this.$message({
                            message: "请输入跳转商品",
                            type: "warning"
                        })
                        return Promise.reject(false)
                    }
                } else if (type == 3) {
                    // 跳转连接
                    if (this.PB.href.length < 10) {
                        this.$message({
                            message: "请输入跳转连接",
                            type: "warning"
                        })
                        return Promise.reject(false)
                    }
                }
            })

            .then(() => {
                this.posting = true;
                const data = {
                    ...this.PB,
                    img: this.PB.img.map(item => item.path).join()
                };
                if (data.type == 1) {
                    data.goodsId = "";
                    data.href = "";
                } else if (data.type == 2) {
                    data.href = ""
                } else if (data.type == 3) {
                    data.goodsId = "";
                }
                return this.$axios({
                    url: "api/config/saveBanner",
                    method: "post",
                    data
                })
            })
            
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "保存成功" : res.msg || "保存失败",
                    type: success ? "success" : "warn"
                });

                if (success) {
                    this.$router.back()
                }
            })
            
            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: "保存失败",
                    type: "error"
                });
                console.warn(reason);
            })
            
            .then(() => {
                this.posting = false
            })
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        }
    },

    created () {

        // 有 id 的情况，在 @/mixin/forEdit 已经处理了，
        // 这个没有 id 也发送请求的目的是获取 岗位、系统 的配置信息
        const id = Number(this.$route.params.id);
        if (!id) {
            this.fetchData(true);
        }
    },
}