<template>
    <div class="page">
        <el-form class="news-edit private as-page pd4"
            label-suffix=":" ref="form" label-width="170px"
            :model="PB" :rules="rules" v-loading="submitDisabled">

            <el-form-item label="资讯位置">
                <el-select v-model="PB.type" disabled>
                    <el-option
                        v-for="opt in messageConTypeEnum"
                        :key="opt.value"
                        :label="opt.name"
                        :value="opt.value"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item :label="currentType.name" prop="content" style="padding-bottom: 90px">
                    <!-- :options="editorOption"
                    @blur="onEditorBlur($event)"
                    @focus="onEditorFocus($event)"
                    @ready="onEditorReady($event)" -->
                <quill-editor v-model="PB.content"
                    :options="editorOption"
                    ref="editor">
                </quill-editor>
            </el-form-item>   

            <el-form-item>
                <el-button type="primary" v-if="auth.save" :disabled="submitDisabled" @click="handleSubmitButton">保存</el-button>
                <el-button :disabled="submitDisabled" @click="handleNavigationBack">返回</el-button>
            </el-form-item>

            <el-upload style="display: none"
                class="upload-row"
                :action="$baseURL + 'api/common/fileSingleUpLoad'"
                :headers="uploadHeaders"
                :limit="1"
                :on-success="handleUploadSuccess"
                :on-error="handleUploadFail"
                :file-list="pictures"
                :on-exceed="handleExceed"
                :before-upload="beforeFileUpload"
                ref="uploader"
            >
                <el-button size="small" ref="uploadbtn" id="hideuploadbtn">点击上传</el-button>
            </el-upload>

        </el-form>
    </div>
</template>

<script>
import forEdit from "@/mixin/forEdit";
import editorOption from "@/utils/quillOpts";

export default {
    name: 'NewsEdit',
    mixins: [forEdit],

    data () {
        return {
            PB: {
                type: 1,
                content: "",        // 富文本的内容
            },
            rules: {
                content: {
                    required: false,
                    message: "请填入内容"
                }
            },
            messageConTypeEnum: [], // 类型的配置
            editorOption,           // 富文本编辑器的配置
            pictures: [],           // 隐藏的 el-upload 的file
            uploadHeaders: {        // 图片上传的请求头
                token: this.$store.state.token
            },

            auth: {
                save: false // 修改权限
            }
        }
    },
    computed: {

        currentType () {
            return this.messageConTypeEnum.find(
                item => item.value == this.PB.type) || {
                    name: "关于我们",
                    value: 1
                }
        }
    },
    methods: {

        // 获取页面数据
        fetchData() {
			if (this.loading) return;
			this.loading = true;

			this.$axios({
				url: "api/config/queryMessageConfByType",
				method: "post",
				data: { type: this.$route.params.id || this.PB.type || this.PB.id }
			})
            .then(res => {
                if (res.code === 2000) {
                    this.messageConTypeEnum.splice(0, this.messageConTypeEnum.length, ...res.data.messageConTypeEnum);
                    this.PB.content = res.data.tMessageConf.content?.addHostToImg(this.$baseURL.slice(0, -1));

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        // 点击提交按钮
        handleSubmitButton () {

            this.$refs.form.validate()

            .then(() => {
                this.posting = true;
                const {type, content} = this.PB;
                return this.$axios({
                    url: "api/config/saveMessageConf",
                    method: "post",
                    data: {
                        type, 
                        content: content.removeImgHost(this.$baseURL)
                    }
                })
            })
            
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "操作成功" : res.msg || "操作失败",
                    type: success ? "success" : "warn"
                });

                if (success) {
                    this.handleNavigationBack();
                }
            })
            
            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: "操作失败",
                    type: "error"
                });
                console.warn(reason);
            })
            
            .then(() => {
                this.posting = false
            })
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        },

        handleExceed() {
          // 上传超过一张图
          this.$message({
            message: "只能上传1张图片",
            type: "error",
          });
        },

        beforeFileUpload(file) {
          // 上传图标之前，判断图标格式
          const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
          const isLt2M = file.size / 1024 / 1024 < 5;
    
          if (!isImage) {
            this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
          }
          if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 5MB!');
          }
          if (isImage && isLt2M) {
            // this.userInterface.uploadIconProcess = true;
            return true;
          }
          return false;
        },

        // 上传文件成功
        handleUploadSuccess (res) {
            this.$refs.uploader.clearFiles()
            if (res.code === 2000) {
                const {oldName, filePath} = res.data.files;

                const quill = this.$refs.editor.quill
                let length = quill.getSelection().index;
                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(length, 'image', this.$baseURL + filePath)
                // 调整光标到最后
                quill.setSelection(length + 1)
            } else {
                this.$message({
                    message: "图片插入失败",
                    type: "error"
                })
            }
        },

        // 上传失败
        handleUploadFail () {
            this.$refs.uploader.clearFiles()
            this.$message({
                message: "图片插入失败",
                type: "error"
            })
        }

    },

    created () {
        this.PB.type = Number(this.$route.params.id)
		this.auth.save = this.$hasAuthFor("api/config/queryMessageConfByType");
    }
}
</script>

<style lang='scss' scoped>
    .news-edit.page { // 写两个类的目标是增加权重覆盖全局那个样式
        overflow: auto;
    }
</style>