<template>
    <!-- 会员权益页面设置 -->
    <el-dialog :visible="viewIsReady"
        @close="handleClose"
        :title="title"
        width="800px">

        <el-table :data="list" :height="380"
            @selection-change="handleSelectionChange" ref="bindTable">

            <el-table-column type="selection"></el-table-column>
            <el-table-column label="权益名称" prop="name"></el-table-column>
            <el-table-column label="数量/折扣">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.num"
                        :ref="'v' + scope.row.id"></el-input>
                </template>
            </el-table-column>
        </el-table>

        <div slot="footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button @click="handConfirmButton" type="primary">保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
import Vue from "vue";
import { Loading } from 'element-ui';
export default {
    name: 'BindBenefits',
    props: ["level"],

    data () {
        return {
            viewIsReady: false,
            rules: {},
			list: [],    // 券列表
            checkedlist   : []
        }
    },

    computed: {
        title () {
            if (this.level?.title) return this.level.title +
                " - 会员权益页面设置";
            else return "会员权益页面设置"
        }
    },

    watch: {
        level (level, old) {
            if (level && level !== old) {
                this.fetchData();
                this.viewIsReady = true;
            }
        }
    },

    methods: {
        fetchData () {
			let loadingRef = Loading.service({
                target: ".bind-benefits .el-dialog"
            });
			const {level} = this

			const reqInfo = this.$axios({ url: "api/level/getRightConfBindInfo", params: {levelId: level.id} })
			const reqVouc = this.$axios({ url: "api/level/queryRightConfAll" })
			Promise.allSettled([reqInfo, reqVouc])
			.then(([{value: resInfo}, {value: resVouc}]) => {
				if (resVouc.code === 2000 && resInfo.code === 2000) {
					const {rightConfAll} = resVouc.data; // 解析全部券的列表
					const list = resInfo.data.bindList.filter(it => it.ischoice); // 选中的券
					let allList = [], checked = [];
					rightConfAll.forEach(it => {
						// it.mixName = `${it.name} - ${it.type == 1 ? "抵扣" + it.amount + "元" : it.discount + "%折扣"}`;
						const itsCheckedObj = list.find(item => item.confId === it.id);
						if (itsCheckedObj) {
							it.num = itsCheckedObj.num || "";
							checked.push(it);
						// } else {
						// 	it.num = 1;
						}
						allList.push(it);
					})
					this.list.push(...allList);
					Vue.nextTick()
					.then(() => {
						checked.forEach(it => {
							this.$refs.bindTable.toggleRowSelection(it, true)
						})
					})


				} else if (resVouc.code !== 1003 && resVouc.code !== 2000) {
					return Promise.reject(resVouc)
				} else if (resInfo.code !== 1003 && resInfo.code !== 2000) {
					return Promise.reject(resInfo)
				}
			})
			.catch(reason => {
				this.$message({
					message: "获取券数据失败",
					type: "warning"
				});
				console.warn("获取券数据失败", reason)
			})
			.then(() => loadingRef && loadingRef.close())
		},

        // el-table 选中事件，更新 data
        handleSelectionChange(e) {
            this.checkedlist.splice(0, this.checkedlist.length, ...e);
        },

        handConfirmButton () {
            let loadingRef;
            const list = this.checkedlist;
			// for (let i = 0; i < list.length; i++) {
			// 	const it = list[i];
			// 	if (!it.num?.trim()) {
			// 		this.$refs[`v${it.id}`].focus();
			// 		return this.$message({
			// 			message: "请填写数量/折扣",
			// 			type: "warning"
			// 		})
			// 	}
			// }
            const data = {
				levelId: this.level.id,
				// confIds: list.map(it => it.id).join(),
				// nums: list.map(it => it.num).join()
				confIdNumss: JSON.stringify(list.map(it => ({
					confId: it.id,
					nums: it.num
				})))
			}
            loadingRef = Loading.service({
                target: ".bind-benefits .el-dialog"
            })
			this.$axios({
				url: "api/level/bindVipRightConf",
				data,
				method: "post"
			})
			.then(res => {
				if (res.code === 2000) {
					this.$message({
						message: "保存成功",
						type: "success"
					})
                    loadingRef && loadingRef.close();
					this.handleClose();
				} else if (res.code !== 1003) return Promise.reject(res)
			})
			.catch(reason => {
				this.$message({
					message: reason.msg||"保存失败",
					type: "warning"
				})
                loadingRef && loadingRef.close();
			})
        },

        handleClose () {
            this.viewIsReady = false;
            this.$refs.bindTable.clearSelection();
            this.checkedlist.splice(0, this.checkedlist.length);
            this.list.splice(0, this.list.length);
            this.$emit("update:level", null);
        }
    }
}
</script>

<style lang='scss' scoped>
</style>