<template>
    <el-dialog
        :visible="viewIsReady"
        @close="handleClose"
        :title="title"
        width="420px"
        class="wx-code"
    >

        <img v-if="!!image" :src="image" alt="二维码">

        <div slot="footer">
            <el-button @click="handleClose" type="primary">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'WxCode',
    props:  ["item"],

    data () {
        return {
            viewIsReady: false,
            image: ""
        }
    },

    watch: {
        item (item, old) {
            if (item && item !== old) {
                this.viewIsReady = true;
                this.fetchData()
            }
        }
    },

    computed: {
        title () {
            return (this.item?.name || "") + "类型劵"
        }
    },

    methods: {
        async fetchData () {
            let loadingInstance = this.$loading(({
                target: ".wx-code .el-dialog__body"
            }));
            
            try {
                const res = await this.$axios({
                    url: "api/couponConf/creatCustCouponWxCode",
                    method: "post",
                    data: {
                        couponConfId: this.item.id
                    }
                })
                // const res = {
                //     code: 2000,
                //     data: {
                //         img: "https://testasp.jindingaus.com/upload/real/166530221372650.jpg"
                //     }
                // }

                if (res.code !== 2000) throw res;
                this.image =this.$baseURL + res.data.img;
                
            } catch (reason) {
                console.log("获取图片失败", reason);
                this.$message({
                    message: reason.msg || "获取图片失败"
                })
                this.handleClose()
            }

            loadingInstance.close();
        },

        handleClose () {
            this.viewIsReady = false;
            this.image = "";
            this.$emit("update:item", null);
        }
    }
}
</script>

<style lang='scss' scoped>
    img {
        display: block;
        height: 40vh;
        max-height: 320px;
        margin: 0 auto;
    }
</style>