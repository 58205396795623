import forList from "@/mixin/forList";

export default {
    name: "BasicMaintainer",
    mixins: [forList],
    noFetchFirst: true,

    data() {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                searchname: "",     // 账号/真实姓名
                searchsys: "",      // 业务方向
                searcharea: "",     // 区域
                searchpost: "",     // 岗位
                searchstate: "",    // 状态
            },
            sysTypeEnum: [],    // 业务方向的选项，元素中的 areaList 属性将用于存储该业务方向的区域选项
            areaList: [],       // 区域列表
            postlist: [],       // 岗位的选项
            stateOpts: [        // 状态的选项
                {
                    label: "正常",
                    value: 0
                },
                {
                    label: "作废",
                    value: 1
                }
            ],

        };
    },

    methods: {

		// 获取列表数据
		fetchData() {
			if (this.loading) return;
			this.loading = true;

			return this.$oxios({
				url: "api/employee/queryList",
				method: "post",
				data: {...this.extractParams()}
			})
            .then(res => {
                if (res.code === 2000) {
                    this.total = res.data.count;
                    const list = res.data.emplist.map(item => {
                        return {
                            ...item,
                            dateAndTime: item.createtime.split(" ")
                        }
                    });
                    this.tableData.splice(0, this.tableData.length, ...list);
                    this.sysTypeEnum.splice(0, this.sysTypeEnum.length, ...res.data.sysTypeEnum);
                    this.postlist.splice(0, this.postlist.length, ...res.data.postlist);

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
		},

        // 当选中的系统变化时，情况选中的区域，并获取该系统的区域配置
        handleSysChange (val, recovery) {
            console.log(recovery)
            if (typeof recovery !== "boolean" || !recovery) {
                this.SP_.searcharea = "";
                this.areaList.splice(0, this.areaList.length);
            }

            if (val) {
                const sys = this.sysTypeEnum.find(item => item.value == val)
                if (!(sys?.areaList)) {
                    this.getAreaList()

                    .then(list => {
                        sys.areaList = list;
                        this.$set(
                            this.sysTypeEnum,
                            this.sysTypeEnum.indexOf(sys),
                            sys
                        )
                        this.areaList.push(...list);
                    })

                    .catch(msg => {
                        this.$message({
                            message: msg,
                            type: "warning"
                        });
                    })
                } else {
                    this.areaList.push(...sys.areaList)
                }
            }
        },

        // 获取选中业务系统的区域
        getAreaList() {
            return this.$axios({
                url: "api/employee/queryArea",
                method: "post",
                data: {
                    systype: this.SP_.searchsys
                }
            }).then(res => {
                const success = 2000 == res.code;

                if (success) {
                    return Promise.resolve(res.data.areaList);
                } else {
                    return Promise.reject(res.msg)
                }
            }).catch(() => {
                return Promise.reject("网络连接失败");
            })
        },


        // 点击 "新增菜单"
        handleAddBtn() {
            this.$router.push("/basic/maintainer/add");
        },

        // 点击表格行操作按钮: 编辑
        handleEdit(index) {
            const data = this.tableData[index];
            this.$router.push("/basic/maintainer/edit/" + (data.id || 5));
        },

        // 点击表格行操作按钮: 作废
        handleDelete(index) {
			const data = this.tableData[index];
			this.$confirm("要作废这个账号吗")

                .then(() => {
					this.loading = true;

					return this.$axios({
						url: "api/employee/deleteEmp",
						method: "post",
						data: { empid : data.id }
					})
				})

				.then(res => {
					const success = 2000 == res.code;

					this.$message({
						message: success ? "作废成功" : res.msg || "作废失败",
						type: success ? "success" : "warning"
					})

					this.loading = false;
					if (success) this.fetchData();
				})

                .catch(reason => {
                    console.warn(reason);
					this.loading = false;
                })
        }

    },

	created () {
		this.auth.save = this.$hasAuthFor("api/employee/saveEmployee");
		this.auth.detail = this.$hasAuthFor("api/employee/queryEmpInfo");
		this.auth.delete = this.$hasAuthFor("api/employee/deleteEmp");

        this.fetchData().then(() => {
            if (this.SP_.searchsys) this.handleSysChange(this.SP_.searchsys, true)
        })
	}
};