<template>
    <div class="basic-news page list-page">
        <div class="page--list" v-loading="loading">
            <div class="scroll-wrapper pd4">
                <el-table :data="tableData">
                    <el-table-column label="资讯位置" prop="typename"></el-table-column>
                    <el-table-column label="更新人" prop="empname"></el-table-column>
                    <el-table-column label="更新时间" prop="updatetime"></el-table-column>
                    <el-table-column label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button v-if="auth.detail" type="text" @click.stop="handleEdit(scope.$index)">
                                编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import forList from "@/mixin/forList";

export default {
    name: 'BasicNews',
    mixins: [forList],

    data () {
        return {
            messageConTypeEnum: [], // 类型配置
        }
    },

    methods: {

        fetchData() {
            if (this.loading) return;
            this.loading = true;

            this.$axios({
                url: "api/config/queryMessageConf",
                method: "post"
            })
            .then(res => {
                if (res.code === 2000 && res.data.messageConfAll) {
                    this.messageConTypeEnum = res.data.messageConTypeEnum;
    
                    const list = res.data.messageConfAll.map(item => {
                        const data = {
                            ...item
                        };
    
                        return data;
                    });
                    this.tableData.splice(0, this.tableData.length, ...list)

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        // 点击表格行操作按钮: 编辑
        handleEdit(index) {
            const data = this.tableData[index];
            this.$router.push("/basic/news/edit/" + (data.type || 5));
        },

    },

	created () {
		this.auth.detail = this.$hasAuthFor("api/config/saveMessageConf");
	}
}
</script>

<style lang='scss' scoped>
    
</style>