import Vue from "vue";
import BindVoucher from "./BindVoucher.vue";
import BindBenefits from "./BindBenefits.vue";
import forList from "@/mixin/forList";

export default {
    name: "MemberLevel",
    mixins: [ forList ],
    components: {
        "bind-voucher": BindVoucher,
        "bind-benefits": BindBenefits
    },

    data() {
        return {
            SP: {          // search params 列表查询参数
            },
            actionList: [  // 表格项（行）的操作菜单
                {
                    label: "编辑",
                    name: "edit"
                },
                {
                    label: "关联券",
                    name: "bindVoucher"
                },
                {
                    label: "设置权益页",
                    name: "bindBenefits"
                },
                {
                    label: "删除",
                    name: "delete"
                }
            ],
			// bindingIndex: -1,           // 正在操作(绑定券弹窗)的项index
			// couponConfList: [],         // 券列表--
            // checkedVoucher: [],         // 选择面板中已经勾选的商品
            // posting: false,             // 状态: 保存中
            // voucherLoading: false,      // 状态：模糊查询商品名称中
			auth: {
				bind: false
			},
            bindingVoucherLevel: null,  // 设置优惠券的等级卡
            bindingBenefitsLevel: null, // 设置权益的等级卡
            detailData: null
        };
    },

	computed: {
		// showAddPupop () { return this.bindingIndex >= 0 },
		// popTitle () {return this.showAddPupop ? `${this.tableData[this.bindingIndex].title} - 会员权益券` : "" },
		// submitDisabled () { return this.posting || this.voucherLoading }
	},

    methods: {
        handleInParam(index) {
            const item = this.tableData[index];
            this.detailData = item.remark
        },
        handleDetailClose() {
            this.detailData = null;
        },
		// 获取列表数据
		fetchData() {
			if (this.loading) return;
			this.loading = true;

			this.$oxios({
				url: "api/level/colList",
				method: "get",
				data: {...this.extractParams()}
			})
			.then(res => {
				if (res.code === 2000 && res.data) {
					this.total = res.data.total;
					const {list} = res.data;
					this.tableData.splice(0, this.tableData.length, ...list.map(it => {
						// 普通会元没有 关联券 菜单
                        it.remarkshow = ''
                        if (it.remark) {
                            var replaceStr = '<p>';//要替换的字符串
                            var replaceStr2 = '</p>';//要替换的字符串
                            it.remarkshow = it.remark.replace(new RegExp(replaceStr, 'gm'), '')
                            it.remarkshow = it.remarkshow.replace(new RegExp(replaceStr2, 'gm'), '')
                        }
						if (it.level == 1) it.actionList = [this.actionList[0], this.actionList[2], this.actionList[3]];
						return it
					}));

				} else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
		},

		// 点击 "新增菜单"
		handleAddBtn() {
			this.$router.push("/basic/level/add");
		},

        handleAction (action, scope) {
            const type = action.name;
			const index = scope.$index;
            switch (type) {
                case "edit":
                    this.handleEdit(index);
                    break;

                case "bindVoucher":
                    // this.bindingIndex = index;
					// this.fetchBindInfo(index);
                    this.bindingVoucherLevel = scope.row
                    break;

                case "bindBenefits":
                    this.bindingBenefitsLevel = scope.row
                    break;

                case "delete":
                    this.handleDelete(index)
                    break;
            }
        },

		// 点击表格行操作按钮: 编辑
		handleEdit(index) {
			const data = this.tableData[index];
			this.$router.push(`/basic/level/edit/${data.id}`);
		},

		// 点击表格行操作按钮: 删除
		handleDelete(index) {
			const data = this.tableData[index];
			this.$confirm("要删除这个等级卡吗").then(() => {
				this.loading = true;
				return this.$axios({
					url: "api/level/delete",
					method: "post",
					data: { id : data.id }
				})
			}).then(res => {
				const success = 2000 == res.code;
				this.$message({
					message: success ? "删除成功" : res.msg || "删除失败",
					type: success ? "success" : "warning"
				})

				this.loading = false;
				if (success) this.fetchData();
			}).catch(reason => {
				console.warn(reason);
				this.loading = false;
			})
		},

		// 获取券列表--
		// fetchBindInfo (index) {
		// 	if (this.voucherLoading) return;
		// 	this.voucherLoading = true;
		// 	if (index == undefined) index = this.bindingIndex;
		// 	const level = this.tableData[index];

		// 	const reqInfo = this.$axios({ url: "api/level/getCouponConf", params: {levelId: level.id} })
		// 	const reqVouc = this.$axios({ url: "api/couponConf/queryCouponConfAll" })
		// 	Promise.allSettled([reqInfo, reqVouc])
		// 	.then(([{value: resInfo}, {value: resVouc}]) => {
		// 		if (resVouc.code === 2000 && resInfo.code === 2000) {
		// 			const {couponConfAll} = resVouc.data; // 解析全部券的列表
		// 			const list = resInfo.data.list.filter(it => it.ischoice); // 选中的券
		// 			let allList = [], checked = [];
		// 			couponConfAll.forEach(it => {
		// 				it.mixName = `${it.name} - ${it.type == 1 ? "抵扣" + it.amount + "元" : it.discount + "%折扣"}`;
		// 				const itsCheckedObj = list.find(item => item.confId === it.id);
		// 				if (itsCheckedObj) {
		// 					it.num = itsCheckedObj.num || 1;
		// 					checked.push(it);
		// 				} else {
		// 					it.num = 1;
		// 				}
		// 				allList.push(it);
		// 			})
		// 			this.couponConfList.push(...allList);
		// 			// this.checkedlist.push(...checked);
		// 			Vue.nextTick()
		// 			.then(() => {
		// 				checked.forEach(it => {
		// 					this.$refs.bindTable.toggleRowSelection(it, true)
		// 				})
		// 			})


		// 		} else if (resVouc.code !== 1003 || resVouc.code !== 2000) {
		// 			return Promise.reject(resVouc)
		// 		} else if (resInfo.code !== 1003 || resInfo.code !== 2000) {
		// 			return Promise.reject(resInfo)
		// 		}
		// 	})
		// 	.catch(reason => {
		// 		this.$message({
		// 			message: "获取券数据失败",
		// 			type: "warning"
		// 		});
		// 		console.warn("获取券数据失败", reason)
		// 	})
		// 	.then(() => this.voucherLoading = false)
		// },

		// handleDialogClose () {
		// 	this.bindingIndex = -1;
		// 	this.checkedlist.splice(0, this.checkedlist.length);
		// 	this.couponConfList.splice(0, this.couponConfList.length);
		// },

        // // 点击确定选择--
        // handConfirmButton () {
		// 	// if (this.posting) return;
        //     // 验证
		// 	const list = this.checkedlist;
		// 	for (let i = 0; i < list.length; i++) {
		// 		const it = list[i];
		// 		if (!it.num) {
		// 			this.$refs[`v${it.id}`].focus();
		// 			return this.$message({
		// 				message: "请填写券的数量",
		// 				type: "warning"
		// 			})
		// 		}
		// 		if (!Number.isInteger(it.num)) {
		// 			this.$refs[`v${it.id}`].focus();
		// 			return this.$message({
		// 				message: "请填写整数",
		// 				type: "warning"
		// 			})
		// 		}
		// 	}
		// 	this.posting = true;

        //     const data = {
		// 		levelId: this.tableData[this.bindingIndex].id,
		// 		confIds: list.map(it => it.id).join(),
		// 		nums: list.map(it => it.num).join()
		// 	}
		// 	this.$axios({
		// 		url: "api/level/bindCouponConf",
		// 		data,
		// 		method: "post"
		// 	})
		// 	.then(res => {
		// 		if (res.code === 2000) {
		// 			this.$message({
		// 				message: "保存成功",
		// 				type: "success"
		// 			})
		// 			this.handleDialogClose();
		// 		} else if (res.code !== 1003) return Promise.reject(res)
		// 	})
		// 	.catch(reason => {
		// 		this.$message({
		// 			message: reason.msg||"保存失败",
		// 			type: "warning"
		// 		})
		// 	})
		// 	.then(() => this.posting = false)
        // },
	},

	created () {
		this.auth.save = this.$hasAuthFor("api/level/save");
		this.auth.detail = this.$hasAuthFor("api/level/info");
		this.auth.delete = this.$hasAuthFor("api/level/delete");
		this.auth.bind = this.$hasAuthFor("api/level/getCouponConf") ||
			this.$hasAuthFor("api/level/bindCouponConf")
		if (!this.auth.bind) this.actionList[1].banned = true;

		this.auth.bindBenefits = this.$hasAuthFor("api/level/bindVipRightConf");
		if (!this.auth.bindBenefits) this.actionList[2].banned = true;
	}
};