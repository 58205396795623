import forList from "@/mixin/forList";

export default {
    name: "Benefits",
    mixins: [forList],

    data() {
        return {
            /**
             * SP_是临时保存用户界面输入的查询参数的数据
             * 真正的列表查询数据保存在SP中（见@mixin/forList）
             * SP_数据只有在用户点击搜索后，才会复制到SP
             */
            SP_: {
                searchName: "",       // 位置
                searchState: "",      // 状态
            },
            bannerPositionEnum: [],   // 位置的选项
            stateOpts: [              // 状态的选项
                {
                    label: "禁用",
                    value: 0
                },
                {
                    label: "启用",
                    value: 1
                }
            ]
        };
    },

    methods: {

		// 获取列表数据
		fetchData() {
			if (this.loading) return;
            
			this.loading = true;
			this.$axios({
				url: "api/level/queryRightConfList",
				params: {...this.extractParams()}
			})
            .then(res => {
                if (res.code === 2000) {
                    this.total = res.data.count;
                    const list = res.data.rightConfList.map(item => {
                        return {
                            ...item,
                            path: (item.path ? item.path.split(",") : []).map(
                                path => this.$baseURL + path)
                        }
                    });
                    this.tableData.splice(0, this.tableData.length, ...list);
                    // this.bannerPositionEnum.splice(0, this.bannerPositionEnum.length, ...res.data.bannerPositionEnum);

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取权益失败", reason);
                this.$message({
                    message: reason.msg || "获取权益失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
		},


        // 点击 "新增菜单"
        handleAddBtn() {
            this.$router.push("/basic/benefits/add");
        },

        // 点击表格行操作按钮: 编辑
        handleEdit(index) {
            const data = this.tableData[index];
            this.$router.push("/basic/benefits/edit/" + (data.id || 5));
        },

        // 点击表格行操作按钮: 删除
        handleDelete(index) {
			const data = this.tableData[index];
			this.$confirm("要删除这个权益吗")

                .then(() => {
					this.loading = true;

					return this.$axios({
						url: "api/level/deleteRightConf",
						method: "post",
						data: { id : data.id }
					})
				})

				.then(res => {
					const success = 2000 == res.code;

					this.$message({
						message: success ? "删除成功" : (res.msg || "删除失败"),
						type: success ? "success" : "warning"
					})

					this.loading = false;
					if (success) this.fetchData();
				})

                .catch(reason => {
                    console.warn(reason);
					this.loading = false;
                })
        },

        // 切换状态
        handleStateSwitch (value, index) {
            const item = this.tableData[index];
            const text = value === 1 ? "启用" : "禁用";

            this.loading = true;
            this.$axios({
                url: "api/level/updateRightConfState",
                method: "post",
                data: {
                    id: item.id,
                    state: value
                }
            })

            .then(res => {
                if (res.code === 2000) {
                    item.disname = text;
                    this.$set(this.tableData, index, item);
                } else if (res.code !== 1003)
                    return Promise.reject(res)
            })

            .catch(reason => {
                console.log("切换权益状态失败", reason);
                this.$message({
                    message: reason?.msg || text + "失败",
                    type: "warning"
                });
                item.state = value === 1 ? 0 : 1;
            })

            .then(() => {
                this.loading = false;
                this.$set(this.tableData, index, item)
            })
        }

    },

	created () {
		this.auth.save = this.$hasAuthFor("api/level/saveRightConf");
		this.auth.switch = this.$hasAuthFor("api/level/updateRightConfState"); // 启用禁用权限
		// this.auth.detail = this.$hasAuthFor("api/level/getRightConfInfo");
		this.auth.delete = this.$hasAuthFor("api/level/deleteRightConf");
	}
};