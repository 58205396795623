import MD5 from "blueimp-md5";
import forEdit from "@/mixin/forEdit";

export default {
    name: 'BasicMaintainerEdit',
    mixins: [forEdit],

    data() {
        return {
            // post body 请求体数据
            PB: {
                id: undefined, // 编辑才有id
                phone: "",     // 电话号码
                name: "",      // 真是新明
                email: "",
                post_id: "",
                remark: "",
                account: "",
                pwd: "",
                isadmin: 0,     // 是否系统管理员
                areaids: [],    // 选中的区域，上传时需要转换数据结构
            },
            rules: {
                phone: [
                    { required: true, message: "请输入手机号码", trigger: "blur" },
                    {
                        validator (rule, value, cb) {
                            const fine = /^\d{10,15}$/.test(value);
                            if (fine) cb();
                            else cb("请输入正确的手机号码")
                        }
                    },
                    {
                        validator: (rule, value) => {
                            const request = () => this.$axios({
                                url: "api/employee/verifyEmp",
                                method: "post",
                                data: {
                                    phone: value,
                                    empid: this.PB.id
                                }
                            });

                            return request().catch(request)
                            .then(res => {
                                if (res.code === 2000)
                                    return Promise.resolve()
                                else if (res.code !== 1003)
                                    return Promise.reject(res.msg)
                            })
                            .catch(reason => {
                                return Promise.reject(reason || "手机号码未验证，请稍后重试")
                            })
                        },
                        trigger: "blur"
                    }
                ],
                name: {
                    required: true,
                    message: "请输入真实姓名",
                    trigger: "blur"
                },
                post_id: {
                    required: true,
                    message: "请选择岗位",
                    trigger: "change"
                },
                account: [
                    { required: true, message: "请输入账号", trigger: "blur" },
                    {
                        validator (rule, value, cb) {
                            const fine = /^[A-Za-z0-9]*$/.test(value);
                            if (fine) cb();
                            else cb("只能输入数字或字母")
                        }
                    },
                    {
                        validator: (rule, value) => {
                            const request = () => this.$axios({
                                url: "api/employee/verifyEmp",
                                method: "post",
                                data: {
                                    account: value,
                                    empid: this.PB.id
                                }
                            });

                            return request().catch(request)
                            .then(res => {
                                if (res.code === 2000)
                                    return Promise.resolve()
                                else if (res.code !== 1003)
                                    return Promise.reject(res.msg)
                            })
                            .catch(reason => {
                                return Promise.reject(reason || "账号未验证，请稍后重试")
                            })
                        },
                        trigger: "blur"
                    }
                ],
                pwd: [
                    { required: false, message: "请输入新账号的密码"},
                    {
                        validator (rule, value, cb) {
                            const fine = value === "" || /^[0-9A-Za-z]{6,20}$/.test(value);
                            if (fine) cb();
                            else cb("密码为6-20位的数字或字母")
                        }
                    },
                ],
                areaids: {
                    required: true,
                    message: "请选择区域",
                    trigger: "blur"
                },
                email: {
                    validator (rule, value, cb) {
                        const fine = value === "" || value === null || /\w+@[a-z0-9]+\.[a-z]{2,4}/.test(value); // null是后台迁移数据时迁出来的
                        if (fine) cb();
                        else cb("请输入正确的电子邮箱地址")
                    }
                }
            },
            sysTypeEnum: [],    // 业务方向的选项，元素中的 areaList 属性将用于存储该业务方向的区域选项
            systype: [],        // 选中的业务系统
            postlist: [],       // 岗位的选项
        }
    },

    computed: {
        // 当前选中的系统的 区域配置
        areaList () {
            return this.systype.map(value => {
                return this.sysTypeEnum.find(
                    item => item.value === value);
            })
        }
    },

    methods: {

        // 获取页面数据，withoutProfile：忽略员工信息，只要配置信息
        fetchData(withoutProfile) {
			if (this.loading) return;
			this.loading = true;

			this.$axios({
				url: "api/employee/queryEmpInfo",
				method: "post",
				data: { empid: this.PB.id }
			})
            .then(res => {
                if (res.code === 2000) {
                    this.sysTypeEnum.splice(0, this.sysTypeEnum.length, ...res.data.sysTypeEnum);
                    this.postlist.splice(0, this.postlist.length, ...res.data.postlist);
    
                    if (!withoutProfile) {
                        const {PB} = this;
                        for (const key in res.data.empInfo) {
                            const value = res.data.empInfo[key];
                            if (key == "areaids" && value) {
                                // 初始化选中区域
                                const areaids = value ? value.split(",") : [];
                                PB.areaids.splice(0, PB.areaids.length, ...areaids);
    
                                // 初始化选中系统
                                let _arr = areaids.map(item => item.split("-")[0]);
                                _arr = Array.from(new Set(_arr)).map(item => Number(item));
                                this.systype = _arr;
                                this.handleSysTypeChange(_arr);
                            } else {
                                PB[key] = res.data.empInfo[key]
                            }
                        }
                        PB.isadmin = res.data.empInfo.is_admin
                    }

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        // 当选中的系统变化时，在选中区域中，去掉不属于选中系统的区域，并获取该系统的区域配置
        handleSysTypeChange (val) {
            // 遍历选中的系统，检查他们对应的数据中有没有区域配置信息
            val.forEach(sysVal => {
                const sys = this.sysTypeEnum.find(item => item.value == sysVal);
                // 如果还没有该系统的区域配置，就获取配置
                if (!sys?.areaList) {
                    sys.areaList = []; // 先用一个空数组占位，防止重复请求

                    this.$axios({
                        url: "api/employee/queryArea",
                        method: "post",
                        data: { systype: sys.value }
                    })
                    
                    .then(res => {
                        const success = 2000 == res.code;
        
                        if (success) {
                            sys.areaList.push(...res.data.areaList.map(area => ({
                                ...area,
                                value: `${area.sysType}-${area.areaId}`,
                                name: area.areaname
                            })));

                        } else {
                            return Promise.reject(res.msg)
                        }
                    })

                    .catch(msg => {
                        this.$message({
                            message: msg || "网络出错，未获取到区域配置",
                            type: "warning"
                        });
                        delete sys.areaList;
                    })

                    .then(() => {
                        this.$set(
                            this.sysTypeEnum,
                            this.sysTypeEnum.indexOf(sys),
                            sys
                        )
                    })
                }
            });

            // 遍历选中的区域，把不属于选中的系统的区域移除
            const {areaids} = this.PB;
            let i = areaids.length - 1;
            for (; i >=0; i--) {
                const idstr = areaids[i];
                const sys = Number(idstr.split("-")[0]);
                if (!val.includes(sys)) areaids.splice(i, 1);
            }
        },

        // 点击提交按钮
        handleSubmitButton () {
            if (this.posting) return
            this.$refs.form.validate()

            .then(() => {
                this.posting = true;
                return this.$oxios({
                    url: "api/employee/saveEmployee",
                    method: "post",
                    data: {
                        ...this.PB,
                        areaids: this.PB.areaids.join(),
                        pwd: this.PB.pwd && MD5(this.PB.pwd)
                    }

                })
            })
            
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "操作成功" : res.msg || "操作失败",
                    type: success ? "success" : "warn"
                });

                if (success) {
                    this.resetPB();
                    // this.fetchData();
                    this.$router.back();
                }
            })
            
            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: "操作失败",
                    type: "error"
                });
                console.warn(reason);
            })
            
            .then(() => {
                this.posting = false
            })
        },

        // 重置表单
        resetPB () {
            this.PB.phone = "";
            this.PB.name = "";
            this.PB.email = "";
            this.PB.post_id = "";
            this.PB.remark = "";
            this.PB.account = "";
            this.PB.pwd = "";
            this.PB.isadmin = 0;
            this.PB.areaids.splice(0, this.PB.areaids.length);
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        }
    },

    created () {

        // 有 id 的情况，在 @/mixin/forEdit 已经处理了，
        // 这个没有 id 也发送请求的目的是获取 岗位、系统 的配置信息
        const id = Number(this.$route.params.id);
        if (!id) {
            this.fetchData(true);
            const rule = this.rules.pwd[0];
            rule.required = true;
            this.$set(this.rules.pwd, 0, rule)
        }
    },
}