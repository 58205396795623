<template>
    <div class="basic-faq page list-page">

        <div class="page--list" v-loading="loading">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <!-- list-tool-bar 是 工具栏 的 布局样式 的抽象组件，详见组件定义 -->
                <list-tool-bar v-if="auth.save">
                    <el-button icon="el-icon-plus" type="primary"
                        @click.stop="handleAddBtn">添加</el-button>
                </list-tool-bar>
            
                <el-table :data="tableData">
                    <el-table-column label="类别" prop="typename"></el-table-column>
                    <el-table-column label="标题" prop="title"></el-table-column>
                    <el-table-column label="排序" prop="sort"></el-table-column>
                    <el-table-column label="适用对象" prop="forsname"></el-table-column>
                    <el-table-column label="操作" fixed="right">
                        <template slot-scope="scope">
                        <el-button v-if="auth.detail"
                            type="text"
                            @click.stop="handleEdit(scope.row)"
                            >编辑</el-button
                        >
                        <el-button v-if="auth.delete"
                            type="text"
                            @click.stop="handleDelete(scope.row)"
                            >删除</el-button
                        >
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!-- list-foot-bar 默认插入元素（分页组件）会被插入右侧，
                slot="left" 会插入左侧（预想为列表选中状态） -->
            <list-foot-bar>
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    :current-page.sync="SP.pageNum"
                    :page-size="SP.pageSize"
                    :total="total"
                    @size-change="handlePageSizeChange"
                    @current-change="handlePageNoChange"
                ></el-pagination>
            </list-foot-bar>
        </div>
        
    </div>
</template>

<script>
import forList from "@/mixin/forList";

export default {
    name: 'BasicFAQ',
    mixins: [forList],

    data () {
        return {
            // search params 列表查询参数
			SP: {
				searchtitle: "", // 搜索标题
			},
            questionTypeEnum: [], // 类别配置选项
        }
    },

    methods: {

		fetchData() {
			if (this.loading) return;
			this.loading = true;

			const data = {...this.extractParams()};
			this.$axios({
				url: "api/config/queryQuestion",
				method: "post",
				data
			})
            .then(res => {
                if (res.code === 2000 && res.data.questionlist) {
    
                    this.total = res.data.count;
                    this.questionTypeEnum = res.data.questionTypeEnum;
    
                    const list = res.data.questionlist.map(item => {
                        const data = {
                            ...item
                        };
    
                        return data;
                    });
                    this.tableData.splice(0, this.tableData.length, ...list)

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
		},


        // 点击 "新增菜单"
        handleAddBtn() {
            this.$router.push("/basic/faq/add");
        },

        // 点击表格行操作按钮: 编辑
        handleEdit(data) {
            this.$router.push("/basic/faq/edit/" + (data.id || 5));
        },

		// 点击表格行操作按钮: 删除
		async handleDelete(data) {
			this.$confirm("确定要删除吗")
			
			.then(() => {
				this.posting = true
				return this.$axios({
					url: "api/config/deleteQuestion",
					method: "post",
					data: {
						id: Number(data.id)
					}
				})
			})

			.then(res => {
				const success = 2000 == res.code;
				this.$message({
					message: success ? "删除成功" : (res.msg || "删除失败"),
					type: success ? "success" : "warning"
				})

				if (success) {
					this.tableData.splice(0, this.tableData.length);
					this.fetchData();
				}
				return success || Promise.reject(res)
			})
			
			.catch(reason => {
				console.warn(reason)
			})
			
			.then(() => {
				this.posting = false
			})
		},

    },

	created () {
		this.auth.save = this.$hasAuthFor("api/config/saveQuestion");
		this.auth.detail = this.$hasAuthFor("api/config/getQuestionInfo");
		this.auth.delete = this.$hasAuthFor("api/config/deleteQuestion");
	}
}
</script>

<style lang='scss' scoped>
    
</style>