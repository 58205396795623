<template>
    <div class="page">
        <el-form class="faq-edit private as-page pd4"
            label-suffix=":" ref="form" label-width="100px"
            :model="PB" :rules="rules" v-loading="submitDisabled">

            <el-form-item label="类别" prop="type">
                <el-select v-model="PB.type">
                    <el-option
                        v-for="opt in questionTypeEnum"
                        :key="opt.value"
                        :label="opt.name"
                        :value="opt.value"
                    ></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="适用对象" prop="fors">
                <el-checkbox-group v-model="PB.fors">
                    <el-checkbox label="1">客户</el-checkbox>
                    <el-checkbox label="2">员工</el-checkbox>
                </el-checkbox-group>
            </el-form-item>

            <el-form-item label="标题" prop="title">
                <el-input v-model.trim="PB.title" maxlength="100" class="width5"></el-input>
            </el-form-item>

            <el-form-item label="排序" prop="sort">
                <el-input v-model="PB.sort" type="number" class="width1"></el-input>
            </el-form-item>

            <el-form-item label="内容" prop="content" style="padding-bottom: 90px">
                <quill-editor v-model="PB.content"
                    :options="editorOption"
                    ref="editor">
                </quill-editor>
            </el-form-item>   

            <el-form-item>
                <el-button v-if="auth.save" type="primary" :disabled="submitDisabled" @click="handleSubmitButton">保存</el-button>
                <el-button :disabled="submitDisabled" @click="handleNavigationBack">返回</el-button>
            </el-form-item>

            <el-upload style="display: none"
                class="upload-row"
                :action="$baseURL + 'api/common/fileSingleUpLoad'"
                :headers="uploadHeaders"
                :limit="1"
                :on-success="handleUploadSuccess"
                :on-error="handleUploadFail"
                :file-list="pictures"
                :on-exceed="handleExceed"
                :before-upload="beforeFileUpload"
                ref="uploader"
            >
                <el-button size="small" ref="uploadbtn" id="hideuploadbtn">点击上传</el-button>
            </el-upload>

        </el-form>
    </div>
</template>

<script>
import forEdit from "@/mixin/forEdit";
import editorOption from "@/utils/quillOpts";

export default {
    name: 'FAQEdit',
    mixins: [forEdit],

    data () {
        return {
            PB: {
                type: "",    // 类别
                title: "",   // 标题
                fors: [],    // 适用对象
                sort: 99,    // 排序
                content: "", // 富文本的内容
            },
            rules: {
                type: {
                    required: true,
                    message: "请选择"
                },
                title: {
                    required: true,
                    message: "请填写"
                },
                fors: {
                    required: true,
                    message: "请勾选"
                },
                content: {
                    required: true,
                    message: "请填入内容"
                }
            },
            questionTypeEnum: [], // 类型的配置
            editorOption,         // 富文本编辑器的配置
            pictures: [],         // 隐藏的 el-upload 的file
            uploadHeaders: {      // 图片上传的请求头
                token: this.$store.state.token
            },

            auth: {
                save: false, // 修改的权限
            }
        }
    },
    computed: {

        currentType () {
            return this.questionTypeEnum.find(
                item => item.value == this.PB.type)
        }
    },
    methods: {

        // 获取页面数据
        fetchData(withoutProfile) {
			if (this.loading) return;
			this.loading = true;

			this.$axios({
				url: "api/config/getQuestionInfo",
				method: "post",
				data: { id: this.PB.id }
			})
            .then(res => {
                if (res.code === 2000) {
                    this.questionTypeEnum.splice(0, this.questionTypeEnum.length,
                        ...res.data.questionTypeEnum);
    
                    if (!withoutProfile) {
                        const {PB} = this;
                        for (const key in PB) {
                            if (key == "fors") {
                                PB[key] = res.data.question[key].split(",")
    
                            } else if (key == "content") {
                                PB[key] = res.data.question[key].addHostToImg(this.$baseURL.slice(0, -1))
    
                            } else {
                                PB[key] = res.data.question[key]
                            }
                        }
                    }

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取数据失败", reason);
                this.$message({
                    message: reason.msg || "获取数据失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        // 点击提交按钮
        handleSubmitButton () {

            this.$refs.form.validate()

            .then(() => {
                this.posting = true;
                const data = {
                        ...this.PB,
                        content: this.PB.content.removeImgHost(this.$baseURL),
                        fors: this.PB.fors.join()
                }
                // return console.log("去掉域名后的数据", data);
                return this.$axios({
                    url: "api/config/saveQuestion",
                    method: "post",
                    data
                })
            })
            
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "操作成功" : res.msg || "操作失败",
                    type: success ? "success" : "warn"
                });

                if (success) {
                    this.handleNavigationBack();
                }
            })
            
            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: "操作失败",
                    type: "error"
                });
                console.warn(reason);
            })
            
            .then(() => {
                this.posting = false
            })
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        },

        handleExceed() {
          // 上传超过一张图
          this.$message({
            message: "只能上传1张图片",
            type: "error",
          });
        },

        beforeFileUpload(file) {
          // 上传图标之前，判断图标格式
          const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
          const isLt2M = file.size / 1024 / 1024 < 5;
    
          if (!isImage) {
            this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
          }
          if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 5MB!');
          }
          if (isImage && isLt2M) {
            // this.userInterface.uploadIconProcess = true;
            return true;
          }
          return false;
        },

        // 上传文件成功
        handleUploadSuccess (res) {
            this.$refs.uploader.clearFiles()
            if (res.code === 2000) {
                const {oldName, filePath} = res.data.files;

                const quill = this.$refs.editor.quill
                let length = quill.getSelection().index;
                // 插入图片，res为服务器返回的图片链接地址
                quill.insertEmbed(length, 'image', this.$baseURL + filePath)
                // 调整光标到最后
                quill.setSelection(length + 1)
            } else {
                this.$message({
                    message: "图片插入失败",
                    type: "error"
                })
            }
        },

        // 上传失败
        handleUploadFail () {
            this.$refs.uploader.clearFiles()
            this.$message({
                message: "图片插入失败",
                type: "error"
            })
        }

    },

    created () {

        // 有 id 的情况，在 @/mixin/forEdit 已经处理了，
        // 这个没有 id 也发送请求的目的是获取 岗位、系统 的配置信息
        const id = Number(this.$route.params.id);
        if (!id) {
            this.fetchData(true);
        }
		this.auth.save = this.$hasAuthFor("api/config/saveQuestion");
    }
}
</script>

<style lang='scss' scoped>
    .faq-edit.page { // 写两个类的目标是增加权重覆盖全局那个样式
        overflow: auto;
    }
</style>