
import forEdit from "@/mixin/forEdit";
import editorOption from "@/utils/quillOpts";

export default {
    name: 'BenefitsAdd',
    mixins: [forEdit],

    data() {
        return {
            // post body 请求体数据
            PB: {
                id: undefined, // 编辑才有id
                name: "",      // 权益名
                path: [],      // 图片
                sort: 99,      // 排序
                content: "",   // 备注
            },
            rules: {
                name: {
                    required: true,
                    message: "请输入名称",
                    trigger: "blur"
                },
                path: {
                    required: true,
                    message: "请上传图片",
                    trigger: "blur"
                },
                sort: {
                    required: true,
                    message: "请输入排序号, 数字越大排序越后",
                    trigger: "blur"
                },
                content: {
                    required: true,
                    message: "请填入内容"
                }
            },
            editorOption,            // 富文本编辑器的配置
            pictures: [],            // 隐藏的 el-upload 的file
            uploadHeaders: {         // 图片上传的请求头
                token: this.$store.state.token
            },
            uploadData: {
                isZip: 1
            },
            posting: false,          // 状态: 上传图片
            timer: null,             // 模糊搜索防抖的定时器
            goodsLoading: false,     // 状态：模糊查询商品名称中
        }
    },

    methods: {

        fetchData() {
			if (this.loading) return;
			this.loading = true;

			this.$axios({
				url: "api/level/getRightConfInfo",
				params: { id: this.PB.id }
			})
            .then(res => {
                if (res.code === 2000) {
                        const {PB} = this;
                        for (const key in PB) {
                            if (key == "path") {
                                PB[key] = [{
                                    name: res.data.rightConfInfo[key].slice(res.data.rightConfInfo[key].lastIndexOf("/")+1),
                                    url: this.$baseURL + res.data.rightConfInfo[key],
                                    path: res.data.rightConfInfo[key]
                                }]

                            } else if (key == "content") {
                                PB[key] = res.data.rightConfInfo[key].addHostToImg(this.$baseURL.slice(0, -1))

                            } else {
                                PB[key] = res.data.rightConfInfo[key]
                            }
                        }

                } else if (res.code !== 1003) return Promise.reject(res)
            })
            .catch(reason => {
                console.warn("获取权益失败", reason);
                this.$message({
                    message: reason.msg || "获取权益失败",
                    type: "warning"
                });
            })
            .then(() => this.loading = false)
        },

        handleExceed() {
          // 上传超过一张图
          this.$message({
            message: "只能上传一张图片",
            type: "error",
          });
        },

        beforeFileUpload(file) {
            // 上传图片之前，判断图标格式
            const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
            const isLt2M = file.size / 1024 / 1024 < 5;
        
            if (!isImage) {
                this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!');
            }
            if (isImage && isLt2M) {
                this.PB.path.push(file)
                return true;
            }
            return false;
        },
        
        handleProgress(e, file) {
            this.posting = true;
            const index = this.PB.path.indexOf(file);
            this.$set(this.PB.path, index, file)
        },

        // 上传文件成功
        handleUploadSuccess (res, file) {
            this.posting = false;
            const index = this.PB.path.indexOf(file);
            if (res.code === 2000) {

                const {oldName, filePath} = res.data.files;
                file.url = this.$baseURL + filePath;
                file.path = filePath;
                this.$set(this.PB.path, index, {
                    name: oldName,
                    url: this.$baseURL + filePath,
                    path: filePath
                })
                this.$refs.form.clearValidate("path")
            } else {
                this.PB.path.splice(index, 1);
                this.$message({
                    message: res.msg,
                    type: "error"
                })
            }
        },

        // 删除文件
        handleIconDelete (index) {
            this.PB.path.splice(index, 1);
        },

        
        // 富文本的上传
        beforeFileUpload1(file) {
            // 上传图标之前，判断图标格式
            const isImage = (file.type === 'image/jpeg' || file.type === 'image/png');
            const isLt2M = file.size / 1024 / 1024 < 5;
      
            if (!isImage) {
              this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
            }
            if (!isLt2M) {
              this.$message.error('上传头像图片大小不能超过 5MB!');
            }
            if (isImage && isLt2M) {
              // this.userInterface.uploadIconProcess = true;
              return true;
            }
            return false;
          },
  
          // 上传文件成功
          handleUploadSuccess1 (res) {
              this.$refs.uploader.clearFiles()
              if (res.code === 2000) {
                  const {oldName, filePath} = res.data.files;
  
                  const quill = this.$refs.editor.quill
                  let length = quill.getSelection().index;
                  // 插入图片，res为服务器返回的图片链接地址
                  quill.insertEmbed(length, 'image', this.$baseURL + filePath)
                  // 调整光标到最后
                  quill.setSelection(length + 1)
              } else {
                  this.$message({
                      message: "图片插入失败",
                      type: "error"
                  })
              }
          },
  
          // 上传失败
          handleUploadFail1 () {
              this.$refs.uploader.clearFiles()
              this.$message({
                  message: "图片插入失败",
                  type: "error"
              })
          },

        // 点击提交按钮
        handleSubmitButton () {
            this.$refs.form.validate()

            .then(() => {
                this.posting = true;
                const data = {
                    ...this.PB,
                    path: this.PB.path.map(item => item.path).join(),
                    content: this.PB.content.removeImgHost(this.$baseURL)
                };
                return this.$axios({
                    url: "api/level/saveRightConf",
                    method: "post",
                    data
                })
            })
            
            .then(res => {
                const success = 2000 == res.code;
                this.$message({
                    message: success ? "保存成功" : res.msg || "保存失败",
                    type: success ? "success" : "warn"
                });

                if (success) {
                    this.$router.back()
                }
            })
            
            .catch(reason => {
                // this.$refs.form.validate 验证失败的拒绝结果是值 false
                // 对于验证失败，不用 message 提示，因为表单控件上已有提示
                reason && this.$message({
                    message: "保存失败",
                    type: "error"
                });
                console.warn(reason);
            })
            
            .then(() => {
                this.posting = false
            })
        },

        // 点击返回
        handleNavigationBack () {
            this.$router.back();
        }
    }
}