import forEdit from "@/mixin/forEdit";
import FormGroupTitle from "@/components/FormGroupTitle.vue";
export default {
    name: 'voucherMessage',
    mixins: [forEdit],
    components: {FormGroupTitle},

    data () {
        return {
            // loading: false,
            serveList        : [],
            templateList     : [],
            customTempList   : [],
            messageTempMap   : null,
            MessagePathTypeEnum: [],
            PB: {
                id           : 0,
                isNotice     : 0,
                weixinNo     : "",
                weixinTemp   : "",
                messageTemp  : "",
                pathType     : 0,
                herfPath     : ""
            },
            rules: {
                // isNotice     : {required: true, message: "请选择是否通知", trigger: "blur"}, 
                weixinNo     : {required: true, message: "请选择公众号",   trigger: "blur"}, 
                weixinTemp   : {required: true, message: "请选择微信模板", trigger: "blur"},
                messageTemp  : {required: true, message: "请选择消息模板", trigger: "blur"},
            },
        }
    },

    computed: {
        monitorTitle () {
            const {weixinNo} = this.PB;
            if (!weixinNo) return "";
            else {
                const item = this.serveList.find(({serveAppId}) =>
                    serveAppId == weixinNo);
                if (item) return item.serveName;
                else return "";
            }
        }
    },

    methods: {
        // 获取页面数据，withoutProfile，只要配置信息
        async fetchData() {
			const loading = this.$loading({
                target: ".page"
            });

            try {
                const {id} = this.PB;
                const res = await this.$axios({
                    url: "api/couponConf/queryCouponConfMessageInfo",
                    params: { id }
                });
                if (res.code === 1003) return;
                if (res.code !== 2000) throw res;

                const {couponConfMap, serveList, templateList, customTempList,
                    messageTempMap, MessagePathTypeEnum} = res.data;
                this.serveList           = serveList;
                this.templateList        = templateList;
                this.customTempList      = customTempList;
                this.MessagePathTypeEnum = MessagePathTypeEnum;
                if (messageTempMap?.content)
                    messageTempMap.content = JSON.parse(messageTempMap.content);
                this.messageTempMap = messageTempMap;
                for (let key in this.PB) {
                    if (key !== id)
                        this.PB[key] = couponConfMap[key]
                }
                loading?.close()

            } catch (reason) {
                console.warn("获取券信息失败", reason);
                this.$message({
                    message: reason.msg || "获取券信息失败",
                    type: "warning"
                });
                loading?.close()
            }
        },

        async fetchTemplateList (weiXinNo) {
			
			// const loading = this.$loading({
            //     target: ".page"
            // });
            this.PB.weixinTemp  = "";
            this.PB.messageTemp = "";
            this.messageTempMap = null;

            try {
                const res = await this.$axios({
                    url: "api/couponConf/queryWeiXinTemp",
                    params: {weiXinNo}
                });
                if (res.code === 1003) return;
                if (res.code !== 2000) throw res;

                const {templateList} = res.data;
                this.templateList   = templateList;
                // loading?.close()

            } catch (reason) {
                
                console.warn("获取微信模板失败", reason);
                this.$message({
                    message: reason.msg || "获取微信模板失败",
                    type: "warning"
                });
                // loading?.close()
            }
        },
        async fetchCustomTempList (weiXinTempNo) {
			
			// const loading = this.$loading({
            //     target: ".page"
            // });
            this.PB.messageTemp = "";
            this.messageTempMap = null;
            
            try {
                const res = await this.$axios({
                    url: "api/couponConf/queryMessageTemp",
                    params: {weiXinTempNo}
                });
                if (res.code === 1003) return;
                if (res.code !== 2000) throw res;

                const {customTempList} = res.data;
                this.customTempList = customTempList;
                // loading?.close()

            } catch (reason) {
                
                console.warn("获取消息模板失败", reason);
                this.$message({
                    message: reason.msg || "获取消息模板失败",
                    type: "warning"
                });
                // loading?.close()
            }
        },
        async fetchMessageTempMap (messageTempNo) {
			
			// const loading = this.$loading({
            //     target: ".page"
            // });
            this.messageTempMap = null;
            
            try {
                const res = await this.$axios({
                    url: "api/couponConf/queryMessageTempInfo",
                    params: {messageTempNo}
                });
                
                if (res.code === 1003) return;
                if (res.code !== 2000) throw res;
                const {messageTempMap} = res.data;
                messageTempMap.content = JSON.parse(messageTempMap.content);
                this.messageTempMap = messageTempMap;
                // loading?.close()

            } catch (reason) {
                
                console.warn("获取模板详情失败", reason);
                this.$message({
                    message: reason.msg || "获取模板详情失败",
                    type: "warning"
                });
                // loading?.close()
            }
        },

        handleDirectToCMS () {
            top.open("https://scms.jindingaus.com/cms/#/login", "_blank")
        },

        async handleSave () {
            const loadingRef = this.$loading({
                target: ".page"
            });

            try {
                await this.$refs.form.validate();
                let data;
                const {isNotice} = this.PB;
                if (!parseInt(isNotice)) data = {
                    id: this.PB.id,
                    isNotice
                };
                else data = this.PB;
                const res = await this.$axios({
                    url: "api/couponConf/saveCouponConfMessage",
                    method: "post",
                    data
                })

                if (res.code === 2000) {
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.handleClose();

                } else if (res.code !== 1003) 
                    throw res;


            } catch (reason) {
                reason && this.$message({
                    message: reason.msg || "操作失败",
                    type: "error"
                });
                console.warn(reason);
                loadingRef && loadingRef.close()
            }
        },

        handleClose () {
            this.$router.back();
        }
    }
}